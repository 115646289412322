/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
/* @import './pretty-checkbox.css'; */

:root{

  --amplify-primary-color: rgb(17, 161, 73);
  --amplify-primary-tint: rgb(14, 136, 60);
  --amplify-primary-shade: rgb(11, 109, 49);
 }

body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  xbackground: rgb(245,245,245);
  background: var( --main-bg-color );
}


.app {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

.app .header
{

  color: white;
  min-height: 40px;
  font-weight: 500;
  padding-left: 10px;
  font-size: 100%;
  display: flex;
  padding-right: 10px;
  flex-wrap: wrap;

  background: var( --header-bg-color );
  color: var( --header-fg-color );

  line-height: 55px;

  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
}

.app .header .header_logo
{
  height: 100%;
  background-image: var( --header-logo );
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  max-width: 200px;
}

.app .header .header_title
{
  flex-grow: 1;
}

.app .header .header_button
{
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 90%;
  font-family: Poppins, sans-serif;
}

.app .section
{
  padding: 20px;
  padding-bottom: 0;
}

.app .header_buttons a
{
  color: unset;
  text-decoration: unset;
}


.action_button {

  display: inline-block;
  background: transparent;
  color: darkgreen;
  padding: 3px 12px;
  border-radius: 8px;
  font-size: 105%;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  Xborder: 2px solid darkgreen;

    border-radius: 4px;
    background-color: var( --accent-color );
    color: white;
    font-family: Poppins, sans-serif;
    letter-spacing: 0.5px;

}

.action_button:active
{
  background: var( --accent-color-darker );
}

.action_button.sm {
  font-size: 95%;
  xborder: 1px solid darkgreen;
  xborder-radius: 7px;
}

.action_button.xxsm {
  font-size: 80%;
  xborder: 1px solid darkgreen;
  xborder-radius: 5px;
}

.action_button.xsm {
  font-size: 85%;
  xborder: 1px solid darkgreen;
  xborder-radius: 5px;
}

.action_button.quiet
{
  border: none;
  background: transparent;
  color: var( --accent-color );
  font-weight: 600;
}

.action_button.tiny
{
  font-size: 90%;
}

.action_button.dashboard_button
{
  font-size: 81%;
  font-weight: 600;
  padding: 16px 20px;

}


.app .dashboard
{
  box-sizing: border-box;
  padding: 20px;
  max-width: 880px;
  margin: auto;
}

.app .dashboard .dashboard_top_buttons
{
  text-align: right;
  max-width: 840px;
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 60px;
  min-height: 60px;

  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ECEAE6;
}

.app .dashboard .dashboard_top_buttons .dashboard_title
{
  font-size: 190%;
  font-weight: 600;
  flex: auto;
  text-align: left;
  color: var( --dashboard-fg-color );
  font-family: Poppins, sans-serif;
}


.applications_list .basic-single
{

}

.applications_holder
{
  max-width: 360px;
}
.application_picker
{
  width: 100%;
  xdisplay: flex;
  flex-wrap: wrap;
  font-size: 90%;
}

.application_picker .app_picker_dropdown
{
  xflex: auto;
  xmin-width: 280px;
  xmax-width: 400px;
  xmargin-right: 10px;
  width: 100%;
  margin-bottom: 5px;
}
.applications_picker_title
{
  margin-bottom: 5px;

}

.application_full_display
{
  xwidth: calc(100% - 10px);
  background: white;
  margin-top: 10px;

  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
  position: relative;

}


.dashboard_sync_button
{
  display: inline-block;
  color: rgb(124, 124, 124);
  margin-left: 20px;
  font-size: 16px;
}

.application_full_display_title
{
  font-size: 95%;
  text-align: center;
  background: #e8e8e8;
  color: black;
  width: 100%;
  box-sizing: border-box;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 1.75;
  ;
}

.application_full_display_detail_table
{
  margin-top: 5px;
  font-size: 85%;
  border: none;
  width: 100%;
}

.application_full_display_detail_table td
{
  text-align: right;
  width: 50%;
}
.application_full_display_detail
{
   font-size: 90%;
   margin-top: 6px;
   margin-bottom: 3px;
}

.application_full_display_content
{
  padding: 10px 20px;
}

.application_full_display_posting
{
  background: rgba(0, 0,0, 0.75);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.application_full_display_posting_content
{
  Xbackground: white;
  Xpadding: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.application_full_display_posting_content_msg
{
  xpadding-top: 10px;
  color: white;
  vertical-align: middle;
  font-weight: 600;
  margin-left: 6px;
}



.application_details
{
  width: calc(100% - 20px);
  background: white;
  margin-top: 5px;

  box-sizing: border-box;
  xborder: 1px solid hsl(0,0%,80%);
  xborder-radius: 6px;
  text-align: left;
  display: inline-block;
  position: relative;
  margin-left: 12px;
  margin-bottom: 10px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 95%;
}

.copy_button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: grey;
}

.copy_button_copied_msg {
  position: absolute;
  right: 0;
  top: -21px;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  background-color: #251833;
  padding: 3px 6px;
  border-radius: 2px;
  z-index: 1;
  white-space: nowrap;

}

.application_details .copy_button
{

  color: grey;
}

.app_details_info
{
  display: flex;
  flex-wrap: wrap;
}

.app_details_link
{
  font-size: 95%;
  flex: auto;
  margin-bottom: 4px;
  margin-top: 4px;

}

.app_details_buttons
{
  margin-top: 4px;
  margin-bottom: 4px;

}

.application_details_content
{
  xdisplay: flex;
}

.application_details_content table
{
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 90%;
  text-align: left;
  font-weight: 600;
  border-collapse: collapse;
}

.application_details_section td
{
  vertical-align: top;
}
.application_details_section td
{
  padding: 8px 0;

}

.app_details_divider td
{
  padding: 0;
  height: 1px;
  background: #cccccc;
}

.application_details .app_details_right
{
  font-weight: 400;
  color: #251833;
}

.application_details .app_details_left
{
  font-weight: 500;
  padding-left: 5px;
}

.group {
  position: relative;
  border: 1px solid silver;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  padding: 5px 20px;
  min-width: 100px;
  margin-top: 8px;
}

.group_title {
  position: absolute;
  left: 10px;
  top: -8px;
  background: white;
  font-size: 13px;
  line-height: 15px;
  padding-left: 4px;
  padding-right: 4px;
  color: rgb(83, 83, 83);

}

.ta_left {
  text-align: left !important;
}

.ta_right {
  text-align: right !important;
}

.latest_submissions_list
{
  max-width: 800px;
  min-height: 80px;
  background: white;
  margin-top: 5px;

  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.latest_submissions_holder {
  max-width: 800px;
}


.submissions_list
{
  max-width: 800px;
  min-height: 80px;
  background: white;
  margin-top: 5px;

  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
}


.submissions_holder
{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 12px;
  margin-top: 6px;
  flex: auto;
}

.submissions_header
{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}


.status_picker_button
{
  flex: auto;
  font-size: 100%;
  position: relative;
}

.submissions_table_header
{
  font-size: 95%;
}


.submissions_title
{
  flex: 1;
}

.status_drop_down_title
{
  display: inline-block;
}
.status_drop_down_button
{
  color: grey;
  transform: scale(1.3);
  margin-left: 3px;
}

.status_drop_down
{
  right: unset !important;
  left: 0;
  top: 23px !important;
}

.SearchBox
{
  width: 100%;
  height: 100%;
  min-height: 30px;
  position: relative;
}

.SearchBox input
{
  width: calc(100% - 27px);
  font-size: 14px;
  height: 26px;
  padding: 0;
  color: #5c5c5c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
  background: white;
  padding-left: 5px;
  padding-right: 27px;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

padding-left: 26px;
width: 100%;
}

.SearchBox input:focus
{
  border: 1px solid blue;
  background: white;

}

.SearchBox .searchButton
{
  height: 26px;
  width: 26px;

  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
  border-right: none;
  outline: none;
  background-color:white;
  background-image: url('./img/icon_search_grey.png');
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  background-color: transparent;
  left: 0;
  top:0;
  border: none;
  margin-left: 0;
}

.searchClearButton
{
  width: 26px;
  display: inline-block;
  margin-left: -26px;
  background-image: url('./img/cross_grey_30.png');
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;

  height: 20px;
  margin-top: 3px;
}


.content {
  xbackground-color: rgb(245,245,245);
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
}

.login_content
{
  background-color: var(--login-bg-color);
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.login_logo
{
    margin-top: 50px;
    width: 200px;
    height: 100px;
    background-image: var(--login-logo);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

}


.content_loading_msg
{
  color: var( --login-contrast-color );
  padding-top: 50px;
}

.sign_in_holder {
  margin: auto;
}

.copy_link_button
{
   padding-left: 0;
   padding-right: 0;
   min-width: 90px;
}

.application_summary {

  border: 1px solid #DBDBDB;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  margin-bottom: 10px;
}

.applications_summary {
  max-width: 840px;
}

.applications_summary_header
{
  background:   var( --collapsible-header-bg );
  color:        var( --collapsible-header-fg );

  font-family:  var( --collapsible-header-font );
  font-weight:  var( --collapsible-header-font-weight );
  font-size:    var( --collapsible-header-font-size );

  line-height: 46px;
  padding-left: 0.75em;
  padding-right: 0.75em;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  cursor: default;

}

.applications_summary_header .collapsed
{
  border-radius: 4px;
}

.applications_summary_name
{
  flex: auto;
}

.applications_summary_title.drop_down_button {
  cursor: default;
  font-size: 105%;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: inline-block;
}

.application_summary_display {
  Xdisplay: flex;
  Xflex-wrap: wrap;
  Xalign-items: flex-start;
}

.position_drop_down_button
{
  color: grey;
  font-size: 120%;
  vertical-align: middle;
}

.application_summary_open_button {
  width: 20px;
  color: var(  --collapsible-header-collapse-button-color );
  transform: scaleX(1.2);
  flex-grow: 0;
  flex-shrink: 0;
}

.submission_status_slug
{
    height: 22px;
    min-width: 101px;

    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;

    box-sizing: border-box;
    border-radius: 11px;
    line-height: 22px;
    cursor: default;
}

.submission_status_slug.large
{
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  min-width: 110px;
}

.submission_status_slug.short
{
  min-width: 60px;
}

.submission_status_slug.Unread
{
  color: rgb(138 70 207);
  background-color: rgba(138, 70, 207,0.15);
}

.submission_status_slug.Declined
{
  color: rgb(203, 92, 51);
  background-color: rgba(302, 92, 51 , 0.15);
}

.submission_status_slug.Interview
{
  color:rgb(69, 136, 177);
  background-color: rgba(69, 136, 177 , 0.15);
}

.submission_status_slug.Shortlisted
{
  color: rgb(53, 158, 39);
  background-color: rgba(53, 158, 39 , 0.15);

  color: rgb(72, 102, 210);
  background-color: rgba(72, 102, 210 , 0.15);


}

.submission_status_slug.Accepted
{
  color: rgb(53, 158, 39);
  background-color: rgba(53, 158, 39 , 0.15);
}

.position_summary_header
{
  font-family:  var( --collapsible-header-font );
  font-weight:  400;
  font-size:    95%;

  line-height: 36px;
  padding-left: 0.75em;
  padding-right: 0.75em;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid transparent;
  border-top: 1px solid rgb(201, 201, 201);
  border-radius: 0;
}

.position_summary {
  border-bottom: 1px solid rgb(201, 201, 201);
  cursor: default;
}

.position_summary.collapsed {
  border-bottom: 1px solid transparent;
}

.collapsed .position_summary_header  {
  background: #f1f1f1;
  border: 1px solid transparent;
  border-bottom: 1px solid transparent;
}


.position_summary_name
{
   flex: auto;
   color: var(  --collapsible-header-fg );
   font-weight: 500;
}

.collapsed .position_summary_name
{
  color: black;
  font-weight: 500;
}

.submissions_display_link_icon
{
  color: var( --accent-color );
  font-size: 17px;
  cursor: pointer;
}

.position_summary .application_full_display
{
  margin: 20px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  max-width: 800px;
}



.position_type_dropdown
{
  background: white;
  width: auto;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 10px;
  z-index: 5;
}

.position_type_drop_down_button
{
  display: inline-block;
  vertical-align: top;
  width: 24px;
  text-align: center;
  cursor: pointer;

  color: white;
  background: #6666cc;
}

.position_type_drop_down_icon
{
  height: 40px;
  font-size: 20px;
}

.position_type_dropdown
{
  display: block;
  text-align: left;
  background: white;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.position_type_dropdown_item
{
  padding: 8px 10px;
  font-family: Poppins, sans-serif;
  text-align: left;
  cursor: pointer;
  padding-right: 40px;
  padding-top: 0;
  padding-bottom: 12px;
  font-size: 98%;
}

.position_type_dropdown_title
{
  xpadding: 5px 10px;
  Xpadding-top: 12px;
  font-size: 12px;
  color: rgb(78, 78, 78);
  text-align: left;
  height: 18px;
  padding-top: 2px;
}

.position_type_dropdown_close
{
  float: right;
  height: 100%;
  width: auto;
  vertical-align: top;
  font-size: 16px;
  padding-right: 5px;
  color: #686868;
 cursor: pointer;
}

.position_type_dropdown_mask
{
  z-index: 4;
  position: fixed;
  background: transparent;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.position_type_dropdown_cancel
{
  padding: 10px 0 8px 0;
  font-family:  sans-serif;
  text-align: center;
  color: navy;
  font-size: 12px;
  cursor: pointer;
  border-top: 1px solid rgb(201, 201, 201);
  margin-top: 5px;
}


.applications_summary.shortlist
{
  min-height: 150px;
}

.shortlisted_header
{
  display: flex;
}

.shortlist_table
{
  border: 1px solid rgb(201, 201, 201);
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.shortlist_title {
  cursor: default;
  font-size: 105%;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  flex-grow: 1;
}

.status_drop_down_items
{
  padding: 10px 15px;
}

.status_type_dropdown_item
{
  color: black;
  font-size: 95%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
}

.create_interview_dialog
{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 500ms;
}

.create_interview_mask
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

.create_interview_form {
  width:310px;
  background: white;
  border-radius: 6px;
  z-index: 1;
  min-height: 200px;
  font-size: 14px;
}

.create_interview_title
{
  text-align: center;
  font-weight: 500;;
  font-size: 18px;
  padding-top: 8px;
}

.create_interview_timezone_holder
{
  width: 88%;
  margin: auto;
  padding-bottom: 20px;
  font-size: 100%;
  font-family: Roboto, sans-serif;
}

.create_interview_timezone_holder_label
{
  font-size: 14px;
  padding-bottom: 3px;
}

.create_interview_date_holder_label
{
  font-size: 14px;
  padding-bottom: 0px;
}

.create_interview_date_picker_holder
{
  width: 88%;
  margin: auto;
  box-sizing: border-box;
  padding-bottom: 20px;;
}

.create_interview_footer
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88%;
  margin: auto;
  margin-bottom: 20px;
}

.create_interview_footer .action_button.submit
{
  margin-left: 10px;

}

.create_interview_footer .action_button.quiet
{
  margin-left: auto
}

.create_interview_date_picker_holder .react-datepicker-wrapper
{
  width: 100%;
}
.create_interview_date_picker_holder .react-datepicker-wrapper input
{
  outline-color: #2684FF;
}

.create_interview_date_picker_holder .create_interview_date_picker
{
  width: 100%;
  padding: 9px 10px;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid silver;
}

.candidate_details
{
   padding: 20px;
   font-family: Poppins, sans-serif;
}

.candidate_details table {
  border-collapse: collapse;
  padding: 0;
  margin: 0;
}

.candidate_details td {
  vertical-align: middle;
  padding-bottom: 8px;
}

.candidate_details td.left {
  font-weight: 600;
  padding-right: 10px;

}

.candidate_details table a
{
  color:var( --main-fg-color );
}

.css-yk16xz-control:hover
{
  xborder-color: rgb(0, 95, 204) !important;
  xbox-shadow: 0 0 1px rgb(0, 95, 204) !important;
}



.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}

.fw700 {
  font-weight: 700;
}

.opacity1 {
  opacity: 1;
}

.inline
{
  display: inline-block;
}



@media only screen and (max-width: 1000px) {
  .app .dashboard .dashboard_top_buttons {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .app .dashboard .dashboard_top_buttons {
    margin-top: 0;
  }
}