
.holder
{
     min-height: 200px;
     box-sizing: border-box;
     font-family: Poppins, sans-serif;
     font-size: 16px;
}

.background
{
    background: var(--login-form-bg-color);
    border-radius: 4px;
    padding: 29px;
    position: relative;
    max-width: 350px;
    box-sizing: border-box;
}

.title
{
    font-weight: 700;
    font-size: 131%;
    color: var(--login-form-fg-color);
    height: 45px;
}

.info
{
    font-weight: 400;
    font-size: 88%;
    color: var(--login-form-fg-color);
    padding-top: 5px;
    padding-bottom: 5px;
}

.info.error
{
    background: rgb(179, 0, 0);
    color: white;
    padding: 5px;
}

.inputLabel
{
    font-weight: 600;
    text-align: left;
    font-size: 81%;
    color: var(--login-form-fg-color);
    margin-top: 12px;
    min-width: 300px;
    letter-spacing: 0.5px;
}

.inputLabelError
{
    color: red;
}

.input
{
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-weight: 400;
    min-height: 46px;
    font-size: 90%;
    font-family: Poppins, sans-serif;
    padding: 0 10px;
    color: var(--login-form-fg-color);

    padding-right: 40px;
}

.button
{
    width: 100%;
    background: var(--accent-color);
    color: white;
    line-height: 46px;
    border-radius: 4px;
    font-weight: 600;
    opacity: 1;
    pointer-events: auto;
    cursor: default;
}

.button_outline
{
    background: white;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);

}

.button_inline
{
    width: auto;
    margin: auto;
    display: inline-block;
    padding: 0px 20px;
    height: 40px;
    line-height: 40px;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.button:active
{
    background: var(--accent-color-darker);
}

.footer
{
    display: flex;
    color: var( --login-form-footer-color );
    font-size: 80%;
    width: 100%;
    box-sizing: border-box;
    line-height: 36px;

}

.footer .button_quiet
{
    text-align: center;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 1;
    cursor: pointer;
}

.passwordButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 36px;
    text-align: center;
    color: rgb(90, 90, 90);
    line-height: 46px;
}


.password_input
{

}